import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../use-firebase.js"
import { useMediaQuery } from "../../shared-functions.js"
import Logo from "./Logo.js";
import { Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { List, Coins } from "phosphor-react";

export default function TopNavbar(props) {

  const location = useLocation();
  const auth = useAuth();
  let isPageWide = useMediaQuery('(min-width: 600px)');

  return (
    <Navbar justify="true" expand="md" fixed="top" variant="light" style={{ backgroundColor: '#FFFFFF', paddingTop: 5 }}>
      <Logo />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="https://app.nikos.ai/login"><h6>Login</h6></Nav.Link>
          <Nav.Link href="https://app.nikos.ai/signup"><h6>Sign up</h6></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );

  return (
    <Navbar justify fixed="top" variant="dark" style={{ textAlign: 'center', paddingTop: 5, paddingBottom: 10, backgroundColor: '#13103C' }}>
      <Logo />
      { location.pathname !== '/' &&
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/earnings"><Badge bg="success" pill><h5><Coins size={24} /> 672 $DO</h5></Badge></Nav.Link>
              <Nav.Link href="/profile"><h6 style={{ marginTop: 5 }}>@testing</h6></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      }
    </Navbar>
  );

  if (!auth.user) {
    return (
      <Navbar justify expand="md" fixed="top" variant="dark" style={{ backgroundColor: '#111111', paddingTop: 15 }}>
        <Logo />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="ml-auto">
            <Nav.Link href="/login"><h6>Login</h6></Nav.Link>
            <Nav.Link href="/signup"><h6>Sign up</h6></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  } else {
    return (
      <Navbar justify expand="md" fixed="top" variant="dark" style={{ backgroundColor: '#111111', paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <Logo />
        <Navbar.Toggle aria-controls="basic-navbar-nav"><List style={{ color: '#999999', borderColor: 'transparent' }} /></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <NavDropdown alignRight className="ml-auto" id="dropdown-button-drop-start" drop="down" title={'@' + auth.user.handle} >
            <NavDropdown.Item href="/dashboard">My Dashboard</NavDropdown.Item>
            <NavDropdown.Item href="/profile">My Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item style={{ color: 'red' }} onClick={() => { auth.signout() }}>Signout</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    )
  };




  // <Nav className={ location.pathname === "/" ? "m-auto" : "mr-auto" }>
  // return (
  //   <Navbar justify expand="md" fixed="top" variant="dark" style={{ paddingTop: 10, backgroundColor: '#111111' }}>
  //     <Logo />
  //     <Navbar.Toggle aria-controls="basic-navbar-nav"><List size="32" color='#EEEEEE' style={{ border: '0px', borderColor: 'transparent' }} /></Navbar.Toggle>
  //       <Navbar.Collapse id="basic-navbar-nav" style={{ textAlign: 'right' }}>
  //         { (!["/","/how-it-works","/faqs"].includes(location.pathname) && !location.pathname.startsWith('/dashboard')) &&
  //           <Nav activeKey={location.pathname} className="mr-auto" style={{ marginTop: 5 }}>
  //             <Nav.Link href='/dashboard'>My Dashboard</Nav.Link>
  //           </Nav>
  //         }
  //         { ["/","/how-it-works","/faqs"].includes(location.pathname) && false &&
  //           <Nav activeKey={location.pathname} className="ml-auto">
  //             <Nav.Link href='/how-it-works'>How it works</Nav.Link>
  //             <Nav.Link href='/faqs'>FAQs</Nav.Link>
  //           </Nav>
  //         }
  //       </Navbar.Collapse>
  //   </Navbar>
  // )

}
