import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import "./App.css"

import { Container, Row, Col } from 'react-bootstrap';

import TopNavbar from "./components/common/TopNavbar.js"
import Sidebar from "./components/common/Sidebar.js";
import Footer from "./components/common/Footer.js";
// import Privacy from "./components/legal/Privacy.js"
// import Terms from "./components/legal/Terms.js"
// import CookiePolicy from "./components/legal/CookiePolicy.js"
import NotFound from "./NotFound.js";
import Frontpage from "./Frontpage.js";
// import Demo from "./Demo.js";
import { ProvideAuth } from "./use-firebase.js"

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <Container fluid style={{ marginTop: 0, minHeight: '100vh', backgroundColor: '#FFFFFF', color: '#111111' }}>
          <TopNavbar />
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', marginTop: 70 }}>
            <Sidebar />
            <Col style={{ width: '85%' }}>
              <Routes>
                <Route path="/notfound" element={<NotFound />} />
                <Route path="/" element={<Frontpage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      </Router>
    </ProvideAuth>
  );
}

// <Col style={{ height: '100vh', width: '85%' }}>
