import React from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Logo from "./Logo.js";
import { useMediaQuery } from "../../shared-functions.js";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from 'react-pro-sidebar';
import { UsersFour, User, Storefront, Ticket, Files, ChartBar, ChalkboardSimple, Money, SquaresFour } from "phosphor-react";

export default function Sidebar(props) {

  const location = useLocation();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)')

  // return (
  //   <div></div>
  // )

  if (location.pathname.startsWith('/dashboard') ||
      location.pathname.startsWith('/profile')
    ) {
    return (
      <Col xs="auto" style={{ height: '97vh', padding: 0 }}>
        <ProSidebar collapsed={isPageWide ? false : true} width="240px">
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem active={location.pathname === '/dashboard' ? true : false} icon={<ChalkboardSimple size={20} weight="bold" />} onClick={() => navigate("/dashboard")}>Dashboard</MenuItem>
              { false && <MenuItem active={location.pathname === '/dashboard/collectibles' ? true : false} icon={<Storefront size={20} weight="bold" />} onClick={() => navigate("/dashboard/collectibles")}>Collectibles</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/nfts' ? true : false} icon={<Storefront size={20} weight="bold" />} onClick={() => navigate("/dashboard/nfts")}>NFTs</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/campaigns' ? true : false} icon={<UsersFour size={20} weight="bold" />} onClick={() => navigate("/dashboard/campaigns")}>Crowdfunds</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/tokens' ? true : false} icon={<Money size={20} weight="bold" />} onClick={() => navigate("/dashboard/tokens")}>Tokens</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/tipjar' ? true : false} icon={<Money size={20} weight="bold" />} onClick={() => navigate("/dashboard/tipjar")}>Tip Jar</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/tickets' ? true : false} icon={<Ticket size={20} weight="bold" />} onClick={() => navigate("/dashboard/tickets")}>Tickets</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/posts' ? true : false} icon={<Files size={20} weight="bold" />} onClick={() => navigate("/dashboard/posts")}>Posts</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/favorites' ? true : false} icon={<Files size={20} weight="bold" />} onClick={() => navigate("/dashboard/favorites")}>Favorites</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/library' ? true : false} icon={<Files size={20} weight="bold" />} onClick={() => navigate("/dashboard/library")}>Library</MenuItem> }
              { false && <MenuItem active={location.pathname === '/dashboard/analytics' ? true : false} icon={<ChartBar size={20} weight="bold" />} onClick={() => navigate("/dashboard/analytics")}>Analytics</MenuItem> }
              <MenuItem active={location.pathname === '/profile' ? true : false} icon={<User size={20} weight="bold" />} onClick={() => navigate("/profile")}>Profile</MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </Col>
    )
  } else {
    return (
      <div></div>
    )
  }


}


// <SubMenu title="Fundraising" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/crowdfunds' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/crowdfunds")}>Crowdfunds</MenuItem>
//   <MenuItem active={location.pathname === '/dashboard/tokens' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tokens")}>Tokens</MenuItem>
//   <MenuItem active={location.pathname === '/dashboard/donations' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/donations")}>Donations</MenuItem>
// </SubMenu>
// <SubMenu title="Collectibles" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/nfts' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/crowdfund")}>NFTs</MenuItem>
// </SubMenu>
// <SubMenu title="Events" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/tickets' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tickets")}>Tickets</MenuItem>
// </SubMenu>
// <SubMenu title="Tools" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/tipjars' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tipjars")}>Tip Jars</MenuItem>
// </SubMenu>
