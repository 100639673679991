import React, { useState, useEffect, useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, addDoc, setDoc, doc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { getStorage } from 'firebase/storage';
import { generateRandomString } from './shared-functions.js';
import { getAuth, signInAnonymously, signInWithPopup, onAuthStateChanged, signOut, GoogleAuthProvider } from "firebase/auth";

const app = initializeApp({
  apiKey: "AIzaSyCK10uI3JpAswakjdQXuByf4-4EoeNTecQ",
  authDomain: "nikos-recruiting.firebaseapp.com",
  projectId: "nikos-recruiting",
  storageBucket: "nikos-recruiting.appspot.com",
  messagingSenderId: "1048540800045",
  appId: "1:1048540800045:web:a6d6bf676de0053a82c4a4",
  measurementId: "G-6MJ2C360GY"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
const provider = new GoogleAuthProvider();

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {

  const [user, setUser] = useState(null);

  const signin = () => {
    return signInAnonymously(auth)
      .then((response) => {
        // console.log("user is", response);
        setUser(response.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  const signout = () => {
    signOut(auth).then(() => {
      setUser(false);
    }).catch((error) => {
      // An error happened.
    });
  };

  const googleAuthenticate = () => {
    return signInWithPopup(auth, provider)
      .then(function(response) {
        let newuser = {
          uid: response.user.uid,
          // firstName: "",
          // lastName: "",
          // name: response.user.displayName,
          email: response.user.email,
          emailVerified: response.user.emailVerified,
          phoneNumber: response.user.phoneNumber,
          avatarUrl: response.user.photoURL,
          bannerUrl: null,
          onboarded: false,
          visibility: 'odoue4oGidmVhqlT8xHyEyH0oWPuAKxm',
          createdTs: Date.now(),
        }
        getDocs(query(collection(db, "users"), where("uid", "==", response.user.uid))).then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(function(doc) {
              let existinguser = doc.data()
              if (existinguser.avatarUrl === undefined) {
                existinguser.avatarUrl = response.user.photoURL
              }
              // if (existinguser.name === undefined || existinguser.name.length === 0) {
              //   existinguser.name = response.user.displayName
              // }
              existinguser.emailVerified = response.user.emailVerified
              existinguser.googleAccessToken = response.credential.accessToken
              setDoc(doc(db, "users", doc.id), existinguser).then(() => {
                 setUser(existinguser)
              })
            })
          } else {
            newuser.onboarded = false
            addDoc(collection(db, "users"), newuser).then(() => {
              setUser(newuser)
            })
          }
        }).catch(error => {
          console.log(error);
          return error;
        })
        return response.user
      }).catch(error => {
        return error;
      });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (_user) => {
      if (_user) {
        getDocs(query(collection(db, "users"), where("uid", "==", _user.uid))).then((querySnapshot) => {
          querySnapshot.forEach(function(doc) {
            setUser(doc.data());
          });
        });
      } else {
        setUser(false);
      }
    });
  }, []);

  return {
    user,
    signin,
    signout,
    googleAuthenticate,
  };

};

// export const signIn = () => {
//   signInAnonymously(auth)
//   .then((u) => {
//     console.log("u", u);
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//   });
// }
