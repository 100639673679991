import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, useMediaQuery } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { collection, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { ArrowRight, ImageSquare, UsersThree, SmileySticker, NumberOne, NumberTwo, NumberThree } from "phosphor-react";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Frontpage() {

  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [waitlistName, setWaitlistName] = useState(null);
  const [waitlistEmail, setWaitlistEmail] = useState(null);
  const [waitlistUrl, setWaitlistUrl] = useState(null);
  const [validated, setValidated] = useState(false);
  const [openWaitlist, setOpenWaitlist] = useState(false);

  useEffect(() => {

    window.scrollTo(0,0);

  }, [])

  const addToWaitlist = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        addDoc(collection(db, "waitlists"), { email: waitlistEmail,
                                              createdTs: Date.now(),
                                              visibility: 'hG38waslVcs7U086RhaZut'
        }).then(() => {
          setWaitlistName("");
          setWaitlistEmail("");
          setWaitlistUrl("");
          // setOpenWaitlist(false);
          doToast("You've been added!")
          setValidated(false);
        })
      }
      setValidated(true);
    }
  };

  const doToast = (text) => {
    toast(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Row style={{ justifyContent: 'center' }}>

      { false &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, minHeight: '50vh' }}>
          <Col xs={11} sm={9} md={8} lg={5} xl={6} style={{ padding: 20, textAlign: 'left', maxWidth: 640 }}>
            <h2 style={{ }}>The missing piece in your employee engagement puzzle</h2>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={12} sm={11} md={11} lg={10} xl={9} style={{ padding: 0 }}>
                <p style={{ marginTop: 0 }}>Nikos enables HRBPs improve employee experience during workplace events and drive organic positive advocacy on social media.</p>
                <Button variant="primary" href="https://app.nikos.ai" style={{ marginTop: 30 }}>Sign up now</Button>
              </Col>
            </Row>
          </Col>
          <Col xs={11} sm={9} md={8} lg={5} xl={5} style={{ padding: 20, textAlign: 'center', maxWidth: 840 }}>
            <Image src={'assets/frontpage/hero.png'} style={{ width: '100%', marginTop: 40 }} />
          </Col>
        </Row>
      }

      { false &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 100 }}>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <ImageSquare size={36} weight="bold" color="purple" />
            <h3 style={{ marginTop: 20 }}>Personalized employee albums</h3>
            <p style={{ marginTop: 20 }}>Offer a fun and interactive experience for employees leveraging facial recognition technology to automatically curate event photos into personalized albums</p>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <UsersThree size={36} weight="bold" color="red" />
            <h3 style={{ marginTop: 20 }}>Authentic social proof</h3>
            <p style={{ marginTop: 20 }}>Enable employees to automatically access their workplace pictures and encourage them to share on social media</p>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <SmileySticker size={36} weight="bold" color="orange" />
            <h3 style={{ marginTop: 20 }}>Enriched experiences</h3>
            <p style={{ marginTop: 20 }}>Be it the Fun-Fridays, get-togethers or community events, , allow employees to relive their favorite moments and share them with colleagues in a unique and enjoyable way</p>
          </Col>
        </Row>
      }

      { false &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
          <h3>High end AI tech at your disposal</h3>
        </Row>
      }

      { false &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <h5>AI Face recognition</h5>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <h5>GDPR compliant</h5>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <h5>Enterprise grade security</h5>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <h5>Integrate with Slack, HRIS</h5>
          </Col>
        </Row>
      }

      { false &&
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
        <h3>How it works</h3>
      </Row>
      }

      { false &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <NumberOne size={36} weight="bold" color="purple" />
            <h5 style={{ marginTop: 20 }}>Organizer uploads pictures</h5>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <NumberTwo size={36} weight="bold" color="red" />
            <h5 style={{ marginTop: 20 }}>Employee accesses personalized albums</h5>
          </Col>
          <Col xs={12} sm={11} md={6} lg={4} xl={3} style={{ padding: 40, textAlign: 'center' }}>
            <NumberThree size={36} weight="bold" color="orange" />
            <h5 style={{ marginTop: 20 }}>Employee shares on social media</h5>
          </Col>
        </Row>
      }

      { true &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
          <Col xs={12} sm={11} md={8} lg={7} xl={6} style={{ padding: 40, textAlign: 'center' }}>
            <h1 style={{ }}>Build showcases of customer love</h1>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={11} sm={10} md={10} lg={9} xl={8} style={{ padding: 0 }}>
                <p>Nikos is a no-code platform that helps teams collect and showcase user-generated testimonials, reviews and stories.</p>
              </Col>
            </Row>
            <Button variant="primary" href="https://app.nikos.ai" style={{ marginTop: 30 }}>Get started for Free</Button>
          </Col>
        </Row>
      }

      { true &&
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 100 }}>
          <Col xs={11} sm={10} md={6} lg={5} xl={5} style={{ padding: 0 }}>
            <Image src="assets/frontpage/hero1.gif" style={{ width: '100%' }} />
          </Col>
          <Col xs={11} sm={10} md={6} lg={5} xl={5} style={{ padding: 0 }}>
            <Image src="assets/frontpage/hero2.gif" style={{ width: '100%' }} />
          </Col>
        </Row>
      }

      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 60, marginBottom: 100 }}>
        <Button variant="primary" href="https://app.nikos.ai">Sign up now</Button>
      </Row>

      <Footer />

      <Modal
        show={openWaitlist}
        centered
        size="md"
        fullscreen='xs-down'
        style={{ backgroundColor: '#2A248899' }}
        onHide={() => setOpenWaitlist(false)}>
        <Modal.Body style={{ backgroundColor: '#13103C', color: '#EEEEEE', padding: 40, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h3>Schedule call</h3>
          <Form noValidate validated={validated} onSubmit={addToWaitlist} style={{ marginTop: 20 }}>
            <Form.Label style={{ marginBottom: 0 }}><h6>Name</h6></Form.Label>
            <Form.Group controlId="name">
              <Form.Control required as="input" type="text" minLength="3" maxLength="180" value={waitlistName} onChange={(e) => setWaitlistName(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Enter a valid name</Form.Control.Feedback>
            </Form.Group>
            <Form.Label style={{ marginTop: 20, marginBottom: 0 }}><h6>Email</h6></Form.Label>
            <Form.Group controlId="email">
              <Form.Control required as="input" type="email" minLength="3" maxLength="180" value={waitlistEmail} onChange={(e) => setWaitlistEmail(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Enter a valid email address</Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" style={{ marginTop: 30 }}>Submit</Button>
            <Form.Label style={{ marginTop: 20 }}><p className="small muted">We care deeply about privacy and will not share your email or use it for any purpose other than reaching out to you regarding this waitlist.</p></Form.Label>
          </Form>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </Row>
  )

}

// <Button href="https://balrl1wa2sw.typeform.com/to/SxSSDp93">Join the waitlist <ArrowRight weight="bold" size={20} style={{ paddingBottom: 3 }} /></Button>
