import React from "react";
// import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default function Footer() {

  // let location = useLocation()

  // return (
  //   <section className="footer-text" style={{ width: '100%', backgroundColor: '#13103C', color: '#EEEEEE', paddingTop: 40, paddingLeft: 30, paddingRight: 30, paddingBottom: 60, marginBottom: 10, textAlign: 'center' }}>
  //     <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
  //       <Col style={{ textAlign: 'center' }}>
  //         <p className="muted small" style={{ color: '#EEEEEE' }}>Made in San Francisco & Bengaluru</p>
  //       </Col>
  //       <Col style={{ textAlign: 'center' }}>
  //         <p className="muted small" style={{ color: '#EEEEEE' }}>hello@nikos.ai</p>
  //       </Col>
  //     </Row>
  //   </section>
  // )

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: '#FFFFFF', color: '#111111', paddingTop: 20, paddingBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10%', marginRight: '10%' }}>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>LEGAL</h6>
          <p className="small" style={{ marginTop: 20 }}><a href="https://firebasestorage.googleapis.com/v0/b/nikos-content.appspot.com/o/policies%2FNikos%20Privacy%20Policy.pdf?alt=media&token=220ec35e-2fe6-4ff8-9b9a-48ad7b686c6c" target="_blank">Privacy Policy</a></p>
          <p className="small" style={{  }}><a href="https://firebasestorage.googleapis.com/v0/b/nikos-content.appspot.com/o/policies%2FNikos%20Terms%20Of%20Use.pdf?alt=media&token=28d62774-40b7-45cb-968a-8cba1f8f0d2e" target="_blank">Terms of Service</a></p>
        </Col>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>Contact</h6>
          <p className="small" style={{ marginTop: 20 }}><a href="mailto:hello@nikos.ai">hello@nikos.ai</a></p>
        </Col>
      </Row>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 60, textAlign: 'center' }}>
        <p className="small text-muted">Built in San Francisco & Bengaluru.<br />Copyright 2023, Nikos Labs.</p>
      </Row>
    </section>
  )

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: '#111111', color: '#EEEEEE', paddingTop: 20, paddingBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: '10%', marginRight: '10%' }}>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>PRODUCT</h6>
          <p style={{ marginTop: 20 }}><a href="/">Home</a></p>
          { false && <p style={{ marginTop: -10 }}><a href="/how-it-works">How it works</a></p> }
          { false && <p style={{ marginTop: -10 }}><a href="/faqs">FAQs</a></p> }
          <p style={{ marginTop: -10 }}><a href="https://calendly.com/fundamental" target="_blank">Schedule a demo</a></p>
        </Col>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>LEGAL</h6>
          <p style={{ marginTop: 20 }}><a href="/">Privacy Policy</a></p>
          <p style={{ marginTop: -10 }}><a href="/">Terms of Service</a></p>
          <p style={{ marginTop: -10 }}><a href="/">Cookie Policy</a></p>
        </Col>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>Contact</h6>
          <p style={{ marginTop: 20 }}><a href="mailto:hello@doschool.xyz">hello@doschool.xyz</a></p>
        </Col>
      </Row>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 60, textAlign: 'center' }}>
        <p className="small text-muted">Built by a 100% remote team. Copyright 2022, Fanflow Inc.</p>
      </Row>
    </section>
  )

}


// <Col style={{ textAlign: 'right' }}>
//   <p className="muted small">
//     Twitter&nbsp;&nbsp;Discord&nbsp;&nbsp;Guide
//   </p>
// </Col>
